/**
 * @description This section is to import all angular modules
 */
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { LOCALE_ID } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import { NgModule } from '@angular/core';
import { NgxPrintModule } from 'ngx-print';
import { registerLocaleData } from '@angular/common';
import localeESP from '@angular/common/locales/es';
import localeESPExtra from '@angular/common/locales/extra/es';
registerLocaleData(localeESP, 'es', localeESPExtra);

/**
 * @description This section is to import all npm modules
 */
import {
  ScfCardModule,
  ScfChartModule,
  ScfCommunicationService,
  ScfDBGridModule,
  ScfDBHeaderModule,
  ScfHeaderModule,
  ScfNotifierModule,
  ScfSidebarModule
} from 'scf-library';
import { SharedModule } from './modules/shared/shared.module';
import { ToastrModule } from 'ngx-toastr';

/**
 * @description This section is to import all iframes required
 */
import { PagesComponent } from './pages.component';
import { SearchOrderClientComponent } from './pages/orders/search-order-client/search-order-client.component';
import { OrderHistoricClientComponent } from './pages/orders/order-historic-client/order-historic-client.component';

/**
 * @description This section is to import all components required
 */
import { AppComponent } from './app.component';
import { DialogFilePreviewComponent } from './components/dialog/dialog-file-preview/dialog-file-preview.component';
import { routing } from './app-routing.module';

/**
 * @description This section is to import all providers and services required
 */
import { CourierProvider } from './providers/courier/courier-provider.service';
import { BlobProviderService } from './providers/evidence/blob-provider.service';
import { EvidenceProvider } from './providers/evidence/evidence-provider.service';
import { FileConversorService } from './services/utils/file-conversor.service';
import { OrderProvider } from './providers/orders/order-provider.service';
import { ShipmentProvider } from './providers/shipment/shipment-provider.service';
import { ToastrAlertsService } from './services/utils/toastr-alerts.service';

@NgModule({
  declarations: [
    AppComponent,
    DialogFilePreviewComponent,
    OrderHistoricClientComponent,
    PagesComponent,
    SearchOrderClientComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    MatMenuModule,
    NgxPrintModule,
    routing,
    ScfCardModule,
    ScfChartModule,
    ScfDBGridModule,
    ScfDBHeaderModule,
    ScfHeaderModule,
    ScfNotifierModule,
    ScfSidebarModule,
    SharedModule,
    ToastrModule.forRoot({
      enableHtml: true,
      timeOut: 6000,
      positionClass: 'toast-top-right',
      preventDuplicates: false,
      closeButton: true,
      toastClass: 'custom-toastr',
      titleClass: 'custom-title-toastr',
      messageClass: 'custom-message-toastr'
    })
  ],
  providers: [
    BlobProviderService,
    EvidenceProvider,
    FileConversorService,
    OrderProvider,
    ScfCommunicationService,
    ShipmentProvider,
    ToastrAlertsService,
    CourierProvider,
    { provide: LOCALE_ID, useValue: 'es-MX' }
  ],
  entryComponents: [DialogFilePreviewComponent],
  bootstrap: [AppComponent]
})
export class AppModule { }
