import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';

import {
  DetailImgExtended,
  EvidenceHistoryData,
  EvidenceHistoryInfoData,
  EvidenceInfoData,
  EvidenceResponse,
  EvidenceToDisplay,
  GenericIncidenceLabels,
  HistoryLiteLabels,
  HistoryLiteTags,
  Order,
  OrderData,
  OrderEvent,
  OrderHistoryShipment,
  ProductInfo,
  ShipmentRequest
} from './../../../interfaces';
import { DownloadFilesService } from '../../../services/utils/download-file.service';
import { EvidenceProvider } from '../../../providers/evidence/evidence-provider.service';
import { environment } from '../../../../environments/environment';
import { GenericIncidenceTags, OrderHistoryLiteLabels, OrderHistoryLiteTags } from './order-historic-client.labels';
import { OrderHistoricClientConstants } from './order-historic-client.constants';
import { OrderProvider } from '../../../providers/orders/order-provider.service';
import { ShipmentOrderReceptionStatus } from '../../../interfaces/order-reception';
import { ShipmentProvider } from '../../../providers/shipment/shipment-provider.service';
import { ToastrAlertsService } from '../../../services/utils/toastr-alerts.service';

import * as _ from 'lodash';
import * as moment from 'moment';

const BASEURI = environment.baseStorageUrl + environment.evidenceContainer;

@Component({
  selector: 'app-order-historic-client',
  templateUrl: './order-historic-client.component.html',
  styleUrls: ['./order-historic-client.component.scss', '../../../app.component.scss'],
  providers: [DownloadFilesService]
})
export class OrderHistoricClientComponent implements OnInit {
  public carouselImages: Array<string>;
  public carouselTitle: string;
  public destinationDate: string;
  public destinationFolio: string;
  public destinationReceptor: string;
  public downloadProofDelivery: boolean;
  public evidencesMerged: Array<string>;
  public folio: string;
  public genericIncidenceLabels: GenericIncidenceLabels;
  public historyColumns: Array<string>;
  public incidenceImages: Array<string>;
  public infoLoaded: boolean;
  public labels: HistoryLiteLabels;
  public logDataSource: MatTableDataSource<OrderEvent>;
  public manualEvidenceURLs: Array<string>;
  public manualEvidences: Array<EvidenceToDisplay>;
  public mobilityEvidenceURLs: Array<string>;
  public order: Order;
  public orderEvidenceAPIInfo: Array<EvidenceHistoryData>;
  public orderFileEvidences: Array<string>;
  public orderFound: boolean;
  public orderLog: Array<OrderEvent>;
  public orderMobilityEvidences: Array<EvidenceHistoryInfoData>;
  public orderOid: string;
  public productColumns: Array<string>;
  public productDataSource: MatTableDataSource<ProductInfo>;
  public products: Array<ProductInfo>;
  public shipmentRequest: ShipmentRequest;
  public shipmentColumns: Array<string>;
  public shipmentDataSource: MatTableDataSource<OrderHistoryShipment>;
  public shipmentEvidences: EvidenceResponse;
  public shipments: Array<OrderHistoryShipment>;
  public shipperOid: string;
  public tags: HistoryLiteTags;
  public tenantId: string;
  constructor(
    private route: ActivatedRoute,
    private downloadService: DownloadFilesService,
    private evidenceProvider: EvidenceProvider,
    private orderProvider: OrderProvider,
    private router: Router,
    private shipmentProvider: ShipmentProvider,
    private toast: ToastrAlertsService
  ) {
    this.route.queryParams.subscribe(params => {
      this.folio = params['folio'];
      this.shipperOid = params['shipperOid'];
    });
    this.tags = OrderHistoryLiteTags;
    this.labels = OrderHistoryLiteLabels;
    this.downloadProofDelivery = false;
  }
  /**
   * @description Event fires when init component
   */
  public async ngOnInit(): Promise<void> {
    this.genericIncidenceLabels = GenericIncidenceTags;
    this.incidenceImages = [];
    this.orderFileEvidences = [];
    this.evidencesMerged = [];
    this.manualEvidenceURLs = [];
    this.mobilityEvidenceURLs = [];
    this.carouselImages = [];
    this.shipments = [];
    this.orderEvidenceAPIInfo = [];
    this.orderMobilityEvidences = [];
    this.products = [];
    await this.searchOrder()
    this.destinationReceptor = this.tags.noInfo;
    this.destinationFolio = this.tags.noInfo;
    this.destinationDate = this.tags.noInfo;
    this.carouselTitle = OrderHistoricClientConstants.CAROUSELTITLE;
    this.shipmentColumns = OrderHistoricClientConstants.SHIPMENT_COLUMNS;
    this.historyColumns = OrderHistoricClientConstants.HISTORY_COLUMNS;
    this.productColumns = OrderHistoricClientConstants.PRODUCT_COLUMNS;
    this.products = this.order.products;
    await this.getOrderLog();
    await this.getShipmentInfo();
    if (this.shipments.length) {
      await this.getAPIEvidences();
      await this.getMobilityEvidences();
      this.setAPIEvidencesURL();
      this.setMobilityURL();

      if (this.manualEvidenceURLs.length && this.orderMobilityEvidences.length) {
        this.setLastEvidenceData(true, true);
        this.mergeEvidences();
        this.carouselImages = this.evidencesMerged;
      } else if (this.manualEvidenceURLs.length) {
        this.setLastEvidenceData(false, true);
        this.carouselImages = this.manualEvidenceURLs;
      } else if (this.orderMobilityEvidences.length) {
        this.setLastEvidenceData(true, false);
        this.carouselImages = this.mobilityEvidenceURLs;
      }
    }
    if (this.products.length) {
      this.productDataSource = new MatTableDataSource(this.products);
    }
    this.infoLoaded = true;
    const imagesDuplicated = new Set(this.carouselImages);
    this.carouselImages = [...imagesDuplicated];
  }

  /**
   * @description Search Order to DL Order
   */
  public async searchOrder(): Promise<void> {
    try {
      await this.orderProvider.getOrderClient(this.folio).then((response) => {
        this.order = response[0];
        this.orderOid = this.order._id;
        this.orderFound = true;
      });
    } catch (error) {
      this.toast.errorAlert(OrderHistoryLiteLabels.toastErrorOrder);
    }
  }

  /**
   * @description manage the creation of the URL for the blob storage of the manual evidences
   */
  public setAPIEvidencesURL(): void {
    for (let i = 0; i < this.orderEvidenceAPIInfo.length; i++) {
      this.orderEvidenceAPIInfo[i].urls = [];
      for (let j = 0; j < this.orderEvidenceAPIInfo[i].file.length; j++) {
        const uri = this.buildFileURI(this.orderEvidenceAPIInfo[i].file[j],
          this.cleanString(this.order.identifier), this.orderEvidenceAPIInfo[i].shipment);
        this.orderEvidenceAPIInfo[i].urls.push(uri);
        this.manualEvidenceURLs.push(uri);
      }
    }
  }

  /**
   * @description manages the depuration of urls of the mobility evidences
   */
  public setMobilityURL(): void {
    for (let i = 0; i < this.orderMobilityEvidences.length; i++) {
      const url = this.orderMobilityEvidences[i].imageURL;
      this.mobilityEvidenceURLs.push(...url);
    }
  }

  /**
   * @description It merges the two groups of evidences, Manual and Mobility
   */
  public mergeEvidences(): void {
    this.evidencesMerged = this.manualEvidenceURLs.concat(this.mobilityEvidenceURLs);
  }

  /**
   * @description It retrieves the info of the order's shipments
   * At this moment, it finds the data for mobility request and shipment-api shipment
   * NOTE: It only will display the information of a confirmed shipment
   */
  public async getShipmentInfo(): Promise<void> {
    const orderShipments = this.order.shipment;
    const currentShipment = this.order.currentShipment;

    const shipmentFiltered = orderShipments.filter(i => {
      if (i.shipmentId.startsWith(OrderHistoricClientConstants.REQUEST_START)) {
        return i;
      }
    });
    for (const orderShipment of shipmentFiltered) {
      let shipmentFolio = '';
      const auxShipment: OrderHistoryShipment = {
        shipmentFolio: '',
        request: orderShipment.shipmentId,
        internalReference: '',
        carrier: '',
        evidences: [],
        driver: '',
        plates: '',
        origin: '',
        inRouteDate: '',
        status: ''
      };
      const request = await this.searchRequest(orderShipment.shipmentId);
      if (!request) {
        continue;
      }
      auxShipment.inRouteDate = request.fechaInicioRuta;
      auxShipment.status = request.estatus;
      auxShipment.status = currentShipment._id === request._id ?
        OrderHistoryLiteLabels.statusShipmentLast : OrderHistoryLiteLabels.statusShipmentPast;
      auxShipment.evidences = request.detalles;

      if (request.embarque.shipmentId) {
        shipmentFolio = request.embarque.shipmentId;
      } else {
        shipmentFolio = request.referenciaInterna;
      }

      const shipment = await this.searchShipment(shipmentFolio);
      auxShipment.shipmentFolio = shipmentFolio;
      auxShipment.internalReference = shipment.internalReference ? shipment.internalReference : '';
      auxShipment.carrier = shipment.transport ? shipment.transport.transportCarrier : OrderHistoryLiteTags.noInfo;
      auxShipment.driver = shipment.transport ? shipment.transport.driver : OrderHistoryLiteTags.noInfo;
      auxShipment.plates = shipment.transport ? shipment.transport.plates : OrderHistoryLiteTags.noInfo;
      auxShipment.origin = shipment.originWarehouse.name;

      this.shipments.push(auxShipment);
    }

    this.shipments = this.shipments.reverse();
    this.shipmentDataSource = new MatTableDataSource(this.shipments);
  }

  /**
   * @description Search a request in order to get aditional information
   * @param folio Request's folio from Mobility
   * @returns requestFound - returns the request found by folio
   */
  public async searchRequest(folio: string): Promise<ShipmentRequest> {
    let requestFound;
    await this.orderProvider.getOrdersByShipment(folio, this.shipperOid)
      .then(res => {
        requestFound = res[0];
        this.shipmentRequest = res[0];
        this.downloadProofDelivery = true;
      }).catch(() =>{});
    return requestFound;
  }

  /**
   * @description Searches a shipment in the Shipment API
   * @param folio Folio from Shipment API that should starts with EM
   * @returns shipmentFound - return the shipment found by folio
   */
  public async searchShipment(folio: string): Promise<ShipmentOrderReceptionStatus> {
    let shipmentFound;
    try {
      const response = await this.shipmentProvider.getShipmentByShipmentId(this.shipperOid, this.shipperOid, folio);
      if (response.length) {
        shipmentFound = response[0];

        return shipmentFound;
      } else {
        this.toast.warningAlert(OrderHistoryLiteLabels.toastWarnNoShipment);
      }
    } catch (error) {
      this.toast.errorAlert(OrderHistoryLiteLabels.toastErrorShipment);

      return shipmentFound;
    }
  }

  /**
   * @description Retrieves the log history of the order found, sorts it and displays it to the table
   */
  public async getOrderLog(): Promise<void> {
    const orderLog = await this.orderProvider.getOrderLog(this.order._id);
    const evidenceLog =  await this.evidenceProvider.getEvidenceLogByOrderFolio(this.order.identifier);

    this.orderLog = orderLog.log[0].event;
    const log = this.mergeOrderAndEvidenceLogs(evidenceLog['logs'], this.orderLog);

    this.logDataSource = new MatTableDataSource(log);
  }

  /**
   * @description It gets the evidences from the evidence-api
   */
  public async getAPIEvidences(): Promise<void> {
    try {
    for (let i = 0; i < this.shipments.length; i++) {
      let auxInfo = [];
      const params = {
        shipmentFolio: this.shipments[i].request,
        tenantId: ''
      };
      const response = await this.evidenceProvider.getByShipment(params);
      if (response) {
        this.shipmentEvidences = response;
        if (this.shipmentEvidences.evidence.ordersEvidence.length) {
          auxInfo = this.shipmentEvidences.evidence.ordersEvidence.filter(elem => elem.orderId === this.order.identifier);
          if (auxInfo.length) {
            auxInfo[0][OrderHistoricClientConstants.KEY_SHIPMENT] = this.shipments[i].request;
            auxInfo[0][OrderHistoricClientConstants.KEY_EVIDENCE_ORIGIN] = OrderHistoricClientConstants.ORIGIN_EVIDENCE_CONTROL;
            this.orderEvidenceAPIInfo = this.orderEvidenceAPIInfo.concat(auxInfo[0]);
          }
          this.orderFileEvidences = this.orderFileEvidences.concat(auxInfo);
        }
      }
    }
    } catch (error) {

    }
    for (let i = 0; i < this.shipments.length; i++) {
      let auxInfo = [];
      const params = {
        shipmentFolio: this.shipments[i].request,
        tenantId: ''
      };
      await this.evidenceProvider.getByShipment(params)
        .then((response) => {
          if (response) {
            this.shipmentEvidences = response;
            if (this.shipmentEvidences.evidence.ordersEvidence.length) {
              auxInfo = this.shipmentEvidences.evidence.ordersEvidence.filter(elem => elem.orderId === this.order.identifier);
              if (auxInfo.length) {
                auxInfo[0][OrderHistoricClientConstants.KEY_SHIPMENT] = this.shipments[i].request;
                auxInfo[0][OrderHistoricClientConstants.KEY_EVIDENCE_ORIGIN] = OrderHistoricClientConstants.ORIGIN_EVIDENCE_CONTROL;
                this.orderEvidenceAPIInfo = this.orderEvidenceAPIInfo.concat(auxInfo[0]);
              }
              this.orderFileEvidences = this.orderFileEvidences.concat(auxInfo);
            }
          }
        })
        .catch((err) => {
          this.toast.errorAlert(OrderHistoryLiteLabels.toastErrorEvidences);
        });
    }
  }

  /**
   * @description It gets the evidences from Mobility request
   * It calls after it obtains the request, other method in order to fulfill the information of the evidence
   */
  public async getMobilityEvidences() {
    try {
      for (const shipment of this.shipments) {
        const orders = await this.orderProvider.getOrdersByShipment(shipment.request, this.shipperOid);
        this.assignAdditionalOrderInfo(orders, orders[0]._id, shipment.request);
      }
    } catch (error) {
      this.toast.errorAlert(OrderHistoryLiteLabels.toastErrorEvidencesMobility);
    }
  }

  /**
   * @description It matches the details' documents from the request with the Order Api Information
   * @param res The request obtained in getMobilityEvidences
   * @param shipmentOid The request oid from the shipment
   * @param shipmentFolio The request folio
   */
  private async assignAdditionalOrderInfo(res: object, shipmentOid: string, shipmentFolio: string): Promise<void> {
    for (const detail of res[0].detalles) {
      if (detail.documentos) {
        for (let i = 0; i < detail.documentos.length; i++) {
          if (detail.documentos[i] === this.order._id) {
            detail.documentos[i] = this.order;
          }
        }
      }
    }
    this.fixRequestEvidences(res[0].detalles, shipmentOid, shipmentFolio);
  }

  /**
   * @description It adds all the extra information needed to get evidence info from Mobility
   * @param stops The stops from the request (detalles)
   * @param shipmentOid The shipment request oid
   * @param shipmentFolio The request Folio
   */
  public fixRequestEvidences(stops: Array<DetailImgExtended>, shipmentOid: string, shipmentFolio: string): void {
    const docInformation: Array<EvidenceHistoryInfoData> = [];

    stops.forEach(stop => {
      if (stop.documentos) {
        stop.documentos.forEach(doc => {
          const evidenceData = [];
          if (doc.evidences) {
            doc.evidences.forEach(pod => {
              const evidenceDetails = stop.evidencias.foto.find(evidence => evidence.img._id === pod.file);
              const evidenceInformation = {
                evidence: evidenceDetails,
                stop: doc.destination.name,
                address: doc.destination.address,
                detail_id: stop._id
              };
              evidenceData.push(evidenceInformation);
              const documentIndex = docInformation.findIndex(elem => elem.documentName === doc.identifier);
              if (documentIndex === -1) {
                const evidenceAux: EvidenceInfoData = {
                  detalle: pod.stopId,
                  documentName: doc.identifier,
                  imageId: [],
                  imageURL: [],
                  receptor: stop.evidencias.firma.nombre,
                  folio: evidenceDetails ? evidenceDetails.folio : '',
                  captureDate: evidenceDetails ? evidenceDetails.timestamp : '',
                  stopName: doc.destination.name,
                  evidenceType: evidenceDetails ? evidenceDetails.evidenceType : '',
                  evidenceStatus: evidenceDetails ? evidenceDetails.estatus : '',
                  evidence: evidenceData,
                  shipmentOid: shipmentOid
                };
                evidenceAux.imageId.push(pod.file);
                docInformation.push(evidenceAux);
              } else {
                docInformation[documentIndex].imageId.push(pod.file);
              }
            });
          }
        });
      }
      if (stop.incidencias && stop.incidencias.length > 0) {
        _.merge(this.incidenceImages, _.without(_.map(stop.incidencias, (incidence) => {
          return incidence.url;
        }), undefined, null, ''));
      }
    });
    docInformation.forEach(ordInfo => {
      const detailGrals = stops.find(detailGral => detailGral._id === ordInfo.detalle);
      const evidenceDetail = detailGrals ? detailGrals.evidencias.foto : [];

      ordInfo.imageId.forEach(id => {
        const found = evidenceDetail.find(evidencia => evidencia.img._id === id);
        if (found) { ordInfo.imageURL.push(found.url); }
      });
    });

    if (docInformation[0] && docInformation[0].imageURL.length) {
      docInformation[0][OrderHistoricClientConstants.KEY_SHIPMENT] = shipmentFolio;
      docInformation[0][OrderHistoricClientConstants.KEY_EVIDENCE_ORIGIN] = OrderHistoricClientConstants.ORIGIN_MOBILITY;
      this.orderMobilityEvidences.push(...docInformation);
    }
  }

  /**
   * @description build a URI based in the params given in
   * @param fileName
   * @param order
   * @param shipmentRequest
   */
  public buildFileURI(fileName: string, order: string, shipmentRequest: string): string {
    const fileURI = BASEURI + shipmentRequest + '/' + order + '/' + fileName;

    return fileURI;
  }

  /**
   * @description It sets the information that the last evidence in destination section needs
   * Note: The evidences from the Evidence API has more priority since it's the process that change the order to liberada
   * @param isMobility flag to know if the order has mobility evidences
   * @param isControl flag to know if the order has evidence api evidences
   */
  public setLastEvidenceData(isMobility: boolean, isControl: boolean): void {
    if (isControl) {
      this.destinationReceptor = this.orderEvidenceAPIInfo[0].receptor ? this.orderEvidenceAPIInfo[0].receptor : this.tags.noInfo;
      this.destinationFolio = this.orderEvidenceAPIInfo[0].folio ? this.orderEvidenceAPIInfo[0].folio : this.tags.noInfo;
      this.destinationDate = this.orderEvidenceAPIInfo[0].timestamp ? this.orderEvidenceAPIInfo[0].timestamp : this.tags.noInfo;
      return;
    } else if (isMobility) {
      const evidences = this.orderMobilityEvidences[0].evidence;
      const lastEvidence = evidences[evidences.length - 1];

      this.destinationReceptor = this.orderMobilityEvidences[0].receptor ? this.orderMobilityEvidences[0].receptor : this.tags.noInfo;
      this.destinationFolio = this.orderMobilityEvidences[0].folio ? this.orderMobilityEvidences[0].folio : this.tags.noInfo;
      this.destinationDate = lastEvidence.evidence.timestampCaptura ? lastEvidence.evidence.timestampCaptura : this.tags.noInfo;
      return;
    }
  }

  /**
   * @description Changes specified string by a '-'
   * @param {string} name Current string
   * @returns {string} String with '-' added
   */
  private cleanString(name: string): string {
    return name.replace(/[|&;$%@"<>()+,/\.?#]/g, '-');
  }

  /**
   * @description Merge and sort logs created by order and evidence
   * @param evidenceLog Evidence log found by orderId
   * @param orderLog Order log found
   * @returns New log merged and sorted by timestamp
   */
  public mergeOrderAndEvidenceLogs(evidenceLog, orderLog): any {
    const orderAndEvidenceLog = [...orderLog];

    for (const log of orderLog) {
      log.timestamp = new Date(log.timestamp);
    }

    for (const log of evidenceLog) {
      const singleEvidenceLog = {
        action: log.action,
        status: log.status,
        timestamp: new Date(log.timestamp),
        user: log.user,
        _id: log._id
      };
      orderAndEvidenceLog.push(singleEvidenceLog);
    }

    orderAndEvidenceLog.sort((x, y) => y.timestamp - x.timestamp);

    return orderAndEvidenceLog;
  }

  /**
   * @description Goes to order search view
   */
  public onClose(): void {
    this.router.navigateByUrl('/', { skipLocationChange: false }).then(() =>
    this.router.navigate(['/search-order']));
  }

  /**
   * @description Gets the POD print title for the selected order
   * @param {OrderData} order Order selected
   * @returns {string} POD file title
   */
  public getPrintTitle(orderData: OrderData): string {
    const withSignature = 'ConFirma';
    const withoutSignature = 'SinFirma';
    return `${orderData['imgSignature'] ? withSignature : withoutSignature}
    _${orderData.folio}
    _${moment().format('DD_MM_YYYY')}`;
  }
}
