export const environment = {
  apiToken: window['appGlobalEnv']['apiToken'],
  apiUrl: window['appGlobalEnv']['apiUrl'],
  baseStorageUrl: window['appGlobalEnv']['baseStorageUrl'],
  courierApiUrl: window['appGlobalEnv']['courierApiUrl'],
  evidenceApiUrl: window['appGlobalEnv']['evidenceApiUrl'],
  evidenceContainer: window['appGlobalEnv']['evidenceContainer'],
  mainContainer: window['appGlobalEnv']['mainContainer'],
  orderApiUrl: window['appGlobalEnv']['orderApiUrl'],
  production: window['appGlobalEnv']['production'],
  shipmentApiUrl: window['appGlobalEnv']['shipmentApiUrl'],
};
